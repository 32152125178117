import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TechUpNorth from "../components/tun/tech_up_north"
import Hedtek from "../components/hedtek/hedtek"
import Unipro from "../components/unipro"
import DuMaurier from "../components/dumaurier"
import RetailExpress from "../components/retail_express"

//TODO: missing later stuff at Retail Express including leadership of small teams
//TODO: this is taken from 00s CV
// I've coded enterprise class applications since 1998 (from 2001 in Java). I've followed the
// complete development life-cycle (inception, evolution, maintenance) for three products. This
// has helped me understand object oriented techniques, design and architecture, appreciate
// the importance of team work and the necessity of being customer oriented. I'm a mathematics
// graduate (1st class). I believe in unit testing. I'm looking for a new challenge.
//TODO:
// Commercial Experience
// JSE
// XML
// Web Services
// Technologies
// Tools
// JEE Middleware
// Technologies
// Containers
// JEE Web
// Technologies
// Data Access
// Tools
// Design
// Methodology
// Platforms
// Containers
// Technologies
// RDBMS
// Version Control
// IoC
// Build
// Testing
// AOP
// Scripting
// Agile
// Formal
// Server
// Swing
// SOAP (1.2), WSDL(1.1)
// XSDL (1.0), Schema (1.1)
// XmlBeans, ActiveSOAP, Axis 1
// EJB (2.0, 2.1), JMS (1.1, JCA1.5)
// Weblogic (8, 9)
// WebSphere (6.1)
// JBoss (3, 4)
// Servlets and JSPs (2.3, 2.4)
// HTML and XHTML, CSS
// Struts (1.2)
// Apache Tomcat (4, 5.5), Jetty (6)
// JDO (1.2), JDBC (1, 2), SQL
// Oracle (8, 9, 10), MySQL (3)
// CVS, Subversion
// PicoContainer, NanoContainer
// Ant(1.5, 1.7)
// JUnit(3), CruiseControl(2.6)
// XDoclet2, JavaAssist (3)
// Python(2.4), Groovy, Jython
// UML (Dia), Patterns (GOF, JEE)
// TDD, XP, DSDM, RAD
// ISO9000
// Linux (RHEL5, Ubuntu), Solaris 10
const EmploymentPage = () => (
  <Layout>
    <SEO title="Résumé" keywords={[`cv`, `resume`, `life`, `personal`, `robertburrelldonkin`]}/>

    <h4>Employment</h4>
    <TechUpNorth/>
    <section>
      <h4>
        Java Developer
        William Hill
        Oct 2012 – Oct 2015 3 years 1 month
      </h4>
      <h5><small>
        Leeds, United Kingdom
      </small></h5>
      <p>
        Primary project a classic message driven Spring Integration JEE featuring large hand-crafted in-memory stores,
        with RCP and HTML5 JavaScript clients. Other highlights include being drafted as cover into JavaScript front end
        team
        developing a betting terminal Chrome app and spinning up a Hadoop cluster for evaluation on SANs.
      </p><p>
      Tens of millions of incoming messages a week from thousands of shops analysed in near-real time (&lt;15 seconds)
      on
      boxes with 32 cores and 256G RAM, each capable of handling more than 250 messages per second peak incoming. Wired
      together using Spring Integration and high-throughput ActiveMQ. Large in-memory stores with trailing database
      persistence to MySQL for resilience and downstream reporting. Original Eclipse RCP with nearer components coded
      as HTML5 JavaScript apps. Routine unit test coverage over 90% with automated volume testing in a like-live
      pre-production environment. Deeply involved in all areas including implementation, design, architecture, testing
      and fault diagnosis. Agile approach, naturally. Scrum methodology, with new releases every other week.
    </p>
      <p>

        I enjoyed applying concurrency lessons from the Advanced Computer Science Masters, transferring knowledge to
        other people on the team and learning more about horse racing.
      </p>
      <p>
        - ActiveMQ
        - Agile
        - AngularJS
        - Apache CXF
        - BDD
        - CometD
        - Concurrency
        - CSS3
        - Eclipse RCP
        - grunt.js
        - Hadoop
        - Hibernate
        - HTML5
        - Jasmine
        - JAX-RS
        - JBehave
        - JMS
        - JMX
        - JUnit4
        - Mentoring
        - Mockito
        - MySQL
        - Pair Programming
        - react.js
        - require.js
        - SASS
        - Scrum
        - Spring
        - Spring Integration
        - Spring Web Services
        - Tomcat
        - TDD
        - JavaScript
        - Javax Websockets
      </p>
    </section>
    <Hedtek/>

    <hr/>
    <RetailExpress/>
    <hr/>
    <DuMaurier/>
    <hr/>
    <Unipro/>
  </Layout>
)

export default EmploymentPage
