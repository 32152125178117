import React from "react"

const Internal = () => (
  <section>
    <h5>Internal Projects</h5>
    <section>
      <h6>Data Protection</h6>
      <p>
        I discovered that there's a lot of admin for any small business here in England.
        Data Protection Act 2018.
        GDPR compliance.
      </p>
    </section>
    <section>
      <h6>Internal Development Projects</h6>
      <p>Proof of concepts. Most notably book keeping.</p>
      <p>Ruby on Rails, Gatsby, React, JavaScript</p>
    </section>
  </section>
)

export default Internal