import React from "react"
import Company from "../company"

const Hedtek = () => (
  <section>
    <h4>Lead Agilist
      Hedtek Ltd
      Feb 2012 – Oct 2012 9 months</h4>
    <Company
      city='Manchester'
      name='Hedtek'
      country='England'
      url='https://hedtek.com/'
      profile='A small software house with a focus on UK academia, offering a range of services as well as
        undertaking internal software development projects.
        Past clients include the BBC, The University of Manchester and JISC.'
    >
    </Company>
    <p>
      Innovative e-assessment system. Research indicated that most offerings used in academia offered very poor
      usability.
      Mark's expertise in user experience, gap existed for a . Contempory technology mixed including a rich javascript
      front running against ruby-on-rails with automated deployment and monitoring orchectrated by nexus and puppet.
    </p><p>
    Mark put together a highly skilled and motivated team, using agile methods to develop a rich and usable e-assessment
    system offering superior usability and productivity to users whether markers or students.
  </p><p>
    Interesting and innovative project but unfortunately unable to generate enough sales against large scale corporate
    competitors with huge marketing budgets.
  </p>
    <p>Completed a tender for JORUM. An educational repository system.</p>
    <p>Assisted with technical and engineering analysis on a report. </p>
    <p>
      An interesting intermezzo taking the train over the Pennines to Hedtek for
      cutting edge Ruby on Rails, CoffeeScript, JQuery, HTML5 and CSS3 in a small, agile team. Initially, working on
      Rails front-end for DSpace then pushing a new product with lots of JavaScript in HTML5 and CSS3 from zero to its
      September launch.
    </p>
    <p>
      Experiences
      - Ruby
      - Rails
      - RSpec
      - Git
      - JavaScript
      - CoffeeScript
      - Bootstrap
      - Compass
      - JQuery
      - JQueryUI
      - JCanvas
      - JSparkline
      - Jasmine
      - HTML5
      - Canvas
      - HAML
      - CSS3
      - Chef (DevOps)
      - DSpace (Java)
      - Kanban
      - PostgreSQL
      - Selenium
      - Capybara
      - Gherkin
      - JMeter
      - EC2
      - Pair Programming
    </p>
  </section>

)

export default Hedtek
