import React from "react"
import PropTypes from "prop-types"
import Outbound from "./outbound"

const Company = ({ name, children, city, country, url, profile }) => (
  <section>
    <h5 style={{ marginBottom: "0.25rem", marginTop: "2rem" }}>
      {name} {url ? <Outbound url={url}/> : ""}
      <span style={{ color: "dimgrey", margin: "1em", fontSize: "smaller" }}>{city}, {country}</span>
    </h5>
    <p style={{ paddingLeft: "1em", paddingRight: "1em" }}>{profile}</p>
    {children}
  </section>
)

Company.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  url: PropTypes.string,
  profile: PropTypes.string,
}

export default Company
