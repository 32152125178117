import React from "react"
import Internal from "./internal"

const TechUpNorth = () => (
  <section>
    <h4>Tech Up North Ltd</h4>
    <Internal/>
  </section>
)

export default TechUpNorth